import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                //Labels
                cardboard: 'Cardboard',

                title_count: 'Items Counted',
                title_weight: 'Total Weight',
                title_revenue: 'Potential Revenue (CHF)',
                title_timeline: "Number of bales processed",
                title_table: "Most recent detections",
                title_num_labels: "Bales labelled",

                timeframe_title: "Set time frame",
                timeframe_today: "Today",
                timeframe_yesterday: "Yesterday",
                timeframe_this_week: "This Week",
                timeframe_last_week: "Last Week",
                timeframe_this_month: "This Month",
                timeframe_last_month: "Last Month",
                timeframe_this_year: "This Year",
                timeframe_all: "All Time",

                material_filter_title: "Materials",
                material_filter_all: "All",

                navbar_hello: "Hi",

                table_time: "Time",
                table_date: "Date",
                table_objecttype: "Object Type",

                sidebar_dashboard: "Overview",
                sidebar_deliveries: "Deliveries",
                sidebar_live: "Live",
                sidebar_plant_feed: "Plant Feed",
                sidebar_reclamations: "Reclamations",
                sidebar_admin_settings: "Settings",
                sidebar_labeling: "Data Labelling",
                sidebar_shift_report: "Shift Report",
                sidebar_material_flow: "Material Flow",

                loading: "Loading . . .",
                device_not_found_error: "Devices not found. Please refresh, or contact the UpCircle team. ",
                user_no_role: "For the current user no role was defined. Please contact an admin.",

                deliveries_card_title: "Deliveries",
                bales_received_card_title: "Bales Received",
                material_received_card_title: "Material Received",
                n_deliveries_card_title: "Number of Deliveries",
                material_piechart_card_title: "Material Distribution",

                delivery_title: "Delivery",
                delivery_create_report: "Create Report",
                delivery_delete: "Delete Delivery",
                delivery_registered_images: "Photos Images",
                delivery_registered_bales: "Registered Bales",
                delivery_mismatch: "mismatch",
                delivery_new: "New Delivery",
                no_deliveries: "No deliveries registered.",
                delivery_image_show_bbox: "Show Bounding Boxes",
                delivery_reclamation_title: "Reclamations Report",
                delivery_reclamation_images: "Photos Reclamation",
                delivery_filter_reclamations: "Only Reclamations",
                delivery_no_images: "No images registered.",

                bale_title: "Bale",
                bale_delete: "Delete Bale",

                property_material: "Material",
                property_weight: "Weight",
                property_weight_kg: "Weight Kg",
                property_supplier: "Supplier",
                property_date: "Date",
                property_time: "Time",
                property_quality: "Quality",
                property_notes: "Notes",
                property_location: "Location",
                property_submitted_by: "Submitted By",
                property_n_bales: "Num. Bales",
                property_registered_bales: "Registered Bales",
                property_conflicts: "Conflicts",
                property_price: "Purchase Price",
                property_photos: "Photos",
                property_truck_license_plate: "Truck License Plate",
                property_dispo_number: "Dispo Number",
                property_bale_mark: "Bale Mark",
                property_weighing_slip_nr: "Weighing Slip Number",

                modal_delivery_delete_title: "Are you sure you want to delete this delivery?" ,
                modal_bale_delete_title: "Are you sure you want to delete this bale?",
                modal_cancel: "Cancel",
                modal_delete: "Delete",

                title_dayreview: "Hourly Overview",
                dayreview_time: "Time",
                dayreview_bales: "Bales",
                dayreview_nbales: "Num Bales",
                dayreview_shift_time: "Shift start",

                title_daysummary: "Summary",
                subtitle_daysummary: "Plant information",
                subtitle_dayreview: "All detections of the day",
                daysummary_weight: "Processed Weight",
                daysummary_num_bales: "Processed Bales",
                daysummary_material: "Processed Materials",
                daysummary_tons: "tons",
                daysummary_bales: "bales",

                title_devicestatus: "Device Status",
                devicestatus_no_disruptions: "No disruptions detected",
                devicestatus_disrupted: "Disruptions detected",
                devicestatus_percentage: "Device was online for",

                labeling_mode_requested: "Labeling Requests",
                labeling_mode_randomized: "Randomized",
                labeling_mode_pairwise: "Pairwise Comparison",
                labeling_title: "Set bale material label",
                quality_labeling_title: "Set bale quality label",
                labeling_no_label: "no label",
                labeling_skip: "Skip label",
                labeling_you_labeled: "You labeled",
                labeling_bales_today: "bales today!",
                alert_low_accuracy: "low confidence",
                labeling_select_recommended: "Recommended",
                labeling_select_others: "Others",
                labeling_request: "Labeling request",
                labeling_no_more_requests: "No more labeling requests found.",

                liveview_title: "Autonomous Bale Scanner - Live View",
                liveview_choice_camera: "Camera Feed",
                liveview_choice_predictions: "AI Analysis", 
                liveview_error: "No stream available. Please contact the UpCircle team.",
                
                live_view_topic_filter_title: "Live View",
                live_view_topic_filter_select: "Choose a source",
                supplier_filter_title: "Supplier",
                multi_select_title_empty: "none",
                multi_select_title: "selected",

                settings_new_client: "New Client",
                settings_users: "Users",
                settings_create_new_user: "Create New User",
                settings_edit_user: "Edit User",
                settings_devices: "Devices",
                settings_create_new_device: "Create New Device",
                settings_edit_device: "Edit Device",
                settings_no_devices_found: "No devices found for selected client.",
                settings_device_id: "Device ID",
                settings_device_display_name: "Display Name",
                settings_device_location_name: "Location Name",
                settings_device_description: "Description",
                settings_device_local_ip: "IP Address",
                settings_device_company: "Company",
                settings_device_emails: "Notification Emails",
                settings_device_add_email: "Add Email",
                settings_device_filters: "Filter Classes",
                settings_device_add_filter: "Add Class",
                settings_device_confirm_reboot: "Do you want to reboot the pipeline?",
                settings_device_confirm_save: "Changes have been saved.",
                settings_save: "Save",
                settings_client_create: "Create Client",
                settings_client_id: "Client ID",
                settings_client_name: "Display Name",
                settings_client_low_accuracy_flag: "Flag Low Accuracy",
                settings_client_add_low_accuracy_flag: "Add Low Accuracy Flag",
                settings_user_last_login: "Last Login",
                settings_user_company: "Company",
                settings_user_name: "Name",
                settings_user_language: "Language",
                settings_user_flags: "User Flags",
                settings_user_add_flag: "Add User Flag",
                settings_user_email: "Email",
                settings_user_password: "Password",
                settings_user_error_fields: "All input fields must be correctly filled out.",
                settings_user_error_email: "This email already exists",
                settings_user_error_pw: "Passwords needs at least 6 characters.",
                settings_user_confirm_create: "Would you like to create a new user? It will take about one minute for the user to be created.",
                settings_user_reset_password: "Send Reset Email",
                settings_user_reset_password_message: "Send email to reset password to user?",
                settings_user_change_credentials: "Change credentials",
                settings_user_change_credentials_message: "Are you sure you want to change the user's login credentials?",
                settings_yes: "Yes",
                settings_cancel: "Cancel",

                shift_report_saving: "Saving...",
                shift_report_offline: "Offline",
                shift_report_last_save: "Last save",

                reclamations_table_card_title: "Reclamations",
                reclamations_nr: "Report Nr.",
                reclamation_card_title: "Reclamation View",
                reclamation_images_title: "Registered Images",
                no_reclamations: "No reclamations registered.",
                reclamation_download_report: "Download Report",
                reclamation_download_report_title: "Download Reclamation Report",
                reclamation_download_report_generating: "Generating Report",
                reclamation_download_report_error: "An error occurred while generating the report.",
                reclamation_delete_note_title: "Are you sure you want to delete this note?",

                timeline_normalize: "Normalize",
                timeline_force_hourly: "Show hourly data",

                demo_mode_company_displayname: "",

                material_flow_loading_error: "Error loading material flow data for given date.",
                material_flow_toggle_bbox_labeling: "Toggle bounding box editing (B)",
                material_flow_edit: "Edit",
                material_flow_delete_text: "Delete bounding box and associated bale",
                material_flow_confirm_text: "Confirm bounding box",
                material_flow_editing: "Draw Bounding Boxes (E)",

                productGroup: "Material Group",
                uncategorized: "uncategorized",
            }
        },
        de: {
            translation: {
                //Labels
                cardboard: 'Karton',
                
                title_count: 'Anzahl Einheiten',
                title_weight: 'Gesamtgewicht',
                title_revenue: 'Potenzielle Einnahmen (CHF)',
                title_timeline: "Anzahl verarbeitete Ballen",
                title_table: "Zuletzt erkannte Einheiten",
                title_num_labels: "Ballen beschriftet",

                timeframe_title: "Zeitraum",
                timeframe_today: "Heute",
                timeframe_yesterday: "Gestern",
                timeframe_this_week: "Diese Woche",
                timeframe_last_week: "Letzte Woche",
                timeframe_this_month: "Dieser Monat",
                timeframe_last_month: "Letzter Monat",
                timeframe_this_year: "Dieses Jahr",
                timeframe_all: "Alles",

                material_filter_title: "Materialien",
                material_filter_all: "Alle",

                navbar_hello: "Hallo",

                table_time: "Zeit",
                table_date: "Datum",
                table_objecttype: "Objekttyp",

                sidebar_dashboard: "Übersicht",
                sidebar_deliveries: "Anlieferung",
                sidebar_live: "Live",
                sidebar_plant_feed: "Anlagen Beschickung",
                sidebar_reclamations: "Reklamationen",
                sidebar_admin_settings: "Einstellungen",
                sidebar_labeling: "Datenbeschriftung",
                sidebar_shift_report: "Schichtrapport",
                sidebar_material_flow: "Materialfluss",

                loading: "Laden . . .",
                device_not_found_error: "Geräte nicht gefunden. Bitte aktualisieren Sie die Seite, oder kontaktieren Sie das UpCircle Team. ",
                user_no_role: "Für den Nutzer wurden keine Rollen definiert. Bitte kontaktieren sie einen Administrator.",

                deliveries_card_title: "Lieferungen",
                bales_received_card_title: "Erhaltene Ballen",
                material_received_card_title: "Erhaltenes Material",
                n_deliveries_card_title: "Anzahl Lieferungen",
                material_piechart_card_title: "Materialverteilung",

                delivery_title: "Lieferung",
                delivery_create_report: "Bericht erstellen",
                delivery_delete: "Lieferung löschen",
                delivery_registered_images: "Fotos Lieferung",
                delivery_registered_bales: "Registrierte Ballen",
                delivery_mismatch: "nicht übereinstimmend",
                delivery_new: "Neue Lieferung",
                no_deliveries: "Keine Lieferungen erfasst.",
                delivery_image_show_bbox: "Detektierungen anzeigen",
                delivery_reclamation_title: "Reklamationsbericht",
                delivery_reclamation_images: "Fotos Reklamation",
                delivery_filter_reclamations: "Nur Reklamationen",
                delivery_no_images: "Keine Fotos erfasst.",

                bale_title: "Ballen",
                bale_delete: "Ballen löschen",

                property_material: "Material",
                property_weight: "Gewicht",
                property_weight_kg: "Gewicht Kg",
                property_supplier: "Lieferant",
                property_date: "Datum",
                property_time: "Zeit",
                property_quality: "Qualität",
                property_notes: "Notizen",
                property_location: "Standort",
                property_submitted_by: "Eingereicht von",
                property_n_bales: "Anz. Ballen",
                property_registered_bales: "Registrierte Ballen",
                property_conflicts: "Konflikte",
                property_price: "Einkaufspreis",
                property_photos: "Fotos",
                property_truck_license_plate: "Kennzeichen LKW",
                property_dispo_number: "Dispo-Nummer",
                property_bale_mark: "Ballenkennzeichnung",
                property_weighing_slip_nr: "Wiegeschein Nummer",

                modal_delivery_delete_title: "Sind Sie sicher, dass Sie diese Lieferung löschen möchten?" ,
                modal_bale_delete_title: "Sind Sie sicher, dass Sie diesen Ballen löschen möchten?",
                modal_cancel: "Abbrechen",
                modal_delete: "Löschen",

                title_dayreview: "Stündliche Übersicht",
                dayreview_time: "Zeit",
                dayreview_bales: "Ballen",
                dayreview_nbales: "Anz. Ballen",
                dayreview_shift_time: "Schichtbeginn",

                title_daysummary: "Zusammenfassung",
                subtitle_daysummary: "Übersicht Anlage",
                subtitle_dayreview: "Erkannte Einheiten des Tages",
                daysummary_weight: "Verarbeitetes Gewicht",
                daysummary_num_bales: "Verarbeitete Ballen",
                daysummary_material: "Verarbeitete Materialien",
                daysummary_tons: "Tonnen",
                daysummary_bales: "Ballen",

                title_devicestatus: "Geräte Status",
                devicestatus_no_disruptions: "Keine Unterbrüche festgestellt",
                devicestatus_disrupted: "Unterbrüche festgestellt",
                devicestatus_percentage: "Das Gerät war Online zu",

                labeling_mode_requested: "Labeling Anfragen",
                labeling_mode_randomized: "Zufällig",
                labeling_mode_pairwise: "Paarweiser Vergleich",
                labeling_title: "Ballenmaterial bestimmen",
                quality_labeling_title: "Ballenqualität bestimmen",
                labeling_no_label: "unbestimmt",
                labeling_skip: "Unbestimmt lassen",
                labeling_you_labeled: "Du hast heute",
                labeling_bales_today: "Ballen beschriftet!",
                alert_low_accuracy: "hohe Unsicherheit",
                labeling_select_recommended: "Empfohlen",
                labeling_select_others: "Andere",
                labeling_request: "Labeling Anfrage",
                labeling_no_more_requests: "Keine weiteren labeling Anfragen gefunden.",

                liveview_title: "Autonomer Ballenscanner - Live View",
                liveview_choice_camera: "Kamera-Feed",
                liveview_choice_predictions: "AI Analyse",
                liveview_error: "Kein Stream verfügbar. Bitte kontaktieren Sie das UpCircle Team.",

                live_view_topic_filter_title: "Live View",
                live_view_topic_filter_select: "Wählen Sie eine Quelle",
                supplier_filter_title: "Lieferant",
                multi_select_title_empty: "keine",
                multi_select_title: "ausgewählt",

                settings_new_client: "Neuer Kunde",
                settings_users: "Benutzer",
                settings_create_new_user: "Neuen Benutzer erstellen",
                settings_edit_user: "Benutzer bearbeiten",
                settings_devices: "Geräte",
                settings_create_new_device: "Neues Gerät erstellen",
                settings_edit_device: "Gerät bearbeiten",
                settings_no_devices_found: "Keine Geräte für ausgewählten Kunden gefunden.",
                settings_device_id: "Geräte-ID",
                settings_device_display_name: "Anzeigename",
                settings_device_location_name: "Standortname",
                settings_device_description: "Beschreibung",
                settings_device_local_ip: "IP Adresse",
                settings_device_company: "Unternehmen",
                settings_device_emails: "Benachrichtigungs E-Mails",
                settings_device_add_email: "E-Mail hinzufügen",
                settings_device_filters: "Filter Klassen",
                settings_device_add_filter: "Klasse hinzufügen",
                settings_device_confirm_reboot: "Möchten Sie die Pipeline neu starten?",
                settings_device_confirm_save: "Änderungen wurden gespeichert.",
                settings_save: "Speichern",
                settings_client_create: "Kunden erstellen",
                settings_client_id: "Kunden-ID",
                settings_client_name: "Anzeigename",
                settings_client_low_accuracy_flag: "Tiefe Genauigkeit markieren",
                settings_client_add_low_accuracy_flag: "Markierung hinzufügen",
                settings_user_last_login: "Letzter Login",
                settings_user_company: "Unternehmen",
                settings_user_name: "Name",
                settings_user_language: "Sprache",
                settings_user_flags: "Benutzerflags",
                settings_user_add_flag: "Benutzerflag hinzufügen",
                settings_user_email: "E-Mail",
                settings_user_password: "Passwort",
                settings_user_error_fields: "Alle Eingabefelder müssen korrekt ausgefüllt sein.",
                settings_user_error_email: "Diese Email existiert bereits",
                settings_user_error_pw: "Passwort muss mindestens 6 Zeichen lang sein.",
                settings_user_confirm_create: "Möchten Sie einen neuen Benutzer erstellen? Es wird ungefähr eine Minute dauern, bis der neue Nutzer sichtbar wird.",
                settings_user_reset_password: "Reset Email senden",
                settings_user_reset_password_message: "E-Mail zum Zurücksetzen des Passworts an den Benutzer senden?",
                settings_user_change_credentials: "Anmeldedaten ändern",
                settings_user_change_credentials_message: "Sind Sie sicher, dass Sie die Anmeldedaten des Benutzers ändern möchten?",
                settings_yes: "Ja",
                settings_cancel: "Abbrechen",

                shift_report_saving: "Speichert...",
                shift_report_offline: "Offline",
                shift_report_last_save: "Zuletzt gespeichert",

                reclamations_table_card_title: "Reklamationen",
                reclamation_nr: "Bericht Nr.",
                reclamation_card_title: "Reklamation Ansicht",
                reclamation_images_title: "Registrierte Bilder",
                no_reclamations: "Keine Reklamationen erfasst.",
                reclamation_download_report: "Bericht Download",
                reclamation_download_report_title: "Reklamation Bericht Download",
                reclamation_download_report_generating: "Generiere Bericht",
                reclamation_download_report_error: "Beim Generieren des Berichts ist ein Fehler aufgetreten.",
                reclamation_delete_note_title: "Sind Sie sicher, dass Sie diese Notiz löschen möchten?",

                timeline_normalize: "Normalisieren",
                timeline_force_hourly: "Stündliche Daten anzeigen",

                material_flow_loading_error: "Fehler beim Laden des Materialstroms für das ausgewählte Datum.",
                material_flow_toggle_bbox_labeling: "Bounding Box Bearbeitung umschalten (B)",
                material_flow_edit: "Bearbeiten",
                material_flow_delete_text: "Bounding Box und assozieerter Ballen löschen",
                material_flow_confirm_text: "Bounding Box bestätigen",
                material_flow_editing_text: "Bounding Box zeichnen (E)",

                demo_mode_company_displayname: "",

                productGroup: "Material Gruppe",
                uncategorized: "unkategorisiert",
            }
        },
    },
});

export default i18n;