import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';

import './Dropdown.scss'

export default function MaterialFilter({ materials, setMaterialFilter }) {
    const { i18n, t } = useTranslation();
    const { logEventCustom } = React.useContext(AuthContext);
    const [ selectedMaterialString, setSelectedMaterialString ] = React.useState(t("material_filter_all"))

    function setSelectedMaterial(material) {
        if (!material) {
            setSelectedMaterialString(t("material_filter_all"));
            setMaterialFilter(undefined);
        } else {
            setSelectedMaterialString(material.name);
            setMaterialFilter(material.id);

            // Log the event to Firebase Analytics
            logEventCustom("filter", {type: "material", value: material.id});
        }
    }

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{t("material_filter_title")}:</button>
                <button className="dropdown-splitbutton-button-large dropdown-splitbutton-button">{selectedMaterialString}</button>
            </div>
            <div className="dropdown-content">
                <a onClick={() => setSelectedMaterial(undefined)}>{t("material_filter_all")}</a>
                {materials.map(material => {
                    return (
                        <a key={material.id} onClick={() => setSelectedMaterial(material)}>{material.name}</a>
                    )
                })}
            </div>
        </div>
    )
}