import React from "react"
import "./TimelineCard.scss"
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../context/AuthContext';
import { format } from "date-fns";

import TimelineCard from "./TimelineCard";

import settingsIcon from "../../../assets/icons/gray/settings.png";

export default function MultiTimelineCard({ cardData, payload }) {
    const { i18n, t } = useTranslation();
    const { labelsets, logEventCustom } = React.useContext(AuthContext);
    const [ category, setCategory ] = React.useState("productGroup");
    const [ isSettingsOpen, setIsSettingsOpen ] = React.useState(false);
    const [ normalize, setNormalize ] = React.useState(false);

    // Make sure that the category exists in the available labelsets if the plot data changes
    React.useEffect(() => {
        if (payload.yValuesDict && !(category in payload.yValuesDict)) {
            setCategory("productGroup");
        }
    }, [payload]);

    if (!payload || !payload.yValuesDict) {
        return <></>
    }

    const options = {
        normalize: normalize,
    }

    const stepUnit = (cardData?.forceHourlyData || format(cardData.timeframe.startDate, "yyyy-MM-dd") === format(cardData.timeframe.endDate, "yyyy-MM-dd")) ? 'hour' : 'day';

    return (
        <div className="multitimelinecard-body">
            <TimelineCard
                cardData={{
                    ...cardData,
                    stepUnit: stepUnit,
                }} 
                payload={{
                    xValues: payload.xValues, 
                    yValuesDict: payload.yValuesDict[category], 
                    labelset: category, 
                    options: options,
                }}
            />
            <div className="multitimeline-settings-bar">
                <div className="category-select">
                    {Object.keys(payload.yValuesDict)
                    .sort((a, b) => { return a === 'productGroup' ? -1 : b === 'productGroup' ? 1 : b.localeCompare(a); })
                    .map((key) => {
                        return <button 
                            key={key} 
                            className={`category-select-button ${key===category ? "selected" : ""}`}
                            onClick={() => {
                                setCategory(key);
                                logEventCustom("chart-category-change", {category: key});
                            }}
                        >
                            {labelsets[key]?.name ? labelsets[key].name : t(key)}
                        </button>
                    })}
                </div>
                <div className="settings">
                    <button className="settings-button" onClick={() => setIsSettingsOpen(prev => !prev)}>
                        <img src={settingsIcon} alt="Chart Settings" />
                    </button>
                    {isSettingsOpen && (
                        <div className="dropdown-content" onMouseLeave={() => setIsSettingsOpen(false)}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="normalize-checkbox"
                                    checked={normalize}
                                    onChange={() => setNormalize(prev => !prev)}
                                />
                                {t("timeline_normalize")}
                            </label>
                            {cardData?.setForceHourlyData && <label>
                                <input
                                    type="checkbox"
                                    name="force-hourly-checkbox"
                                    checked={cardData.forceHourlyData}
                                    onChange={() => cardData.setForceHourlyData(prev => !prev)}
                                />
                                {t("timeline_force_hourly")}
                            </label>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}