import React from 'react'
import { useTranslation } from "react-i18next";

import "./LabelingMotivationalPanel.scss"
import { AuthContext } from '../../context/AuthContext.js';
import { firestore } from '../../context/FirebaseConfig.js';
import { doc, onSnapshot } from 'firebase/firestore'
import { format } from "date-fns";

import MotivationalLines from './MotivationalLines.jsx';

export default function LabelingMotivationalPanel() {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [userLabelsCount, setUserLabelsCount] = React.useState(0);
    const unsubscribeLabelsCount = React.useRef(null);

    React.useEffect(() => {
        async function getUserLabelsCount() {
            /* Get statistic on how many labels the user counted on the given day */
            const dateString = format(new Date(), "yyyy-MM-dd");
            const firebaseQuery = doc(firestore, "clients", currentUser.company.company, "labelling_statistics", dateString);
            const newUnsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
                if (snapshot.exists && snapshot.data()) {
                    if (currentUser.uid in snapshot.data().labellers) {
                        setUserLabelsCount(snapshot.data().labellers[currentUser.uid]);
                    } else {
                        setUserLabelsCount(0);
                    }
                } else {
                    setUserLabelsCount(0);
                }
            });
    
            unsubscribeLabelsCount.current = () => newUnsubscribe();
        }
        getUserLabelsCount();
    }, []);

    return (
        <>
        {userLabelsCount > 0 &&
            <div className="label-count-field">
                <div className="label-count-line">
                    <p>{t("labeling_you_labeled")}</p>
                    <p className="label-count-number">{userLabelsCount}</p>
                    <p>{t("labeling_bales_today")}</p>
                </div>
                {userLabelsCount > 0 && 
                <MotivationalLines numLabeled={userLabelsCount}/>}
            </div>
        }
        </>
    )
}