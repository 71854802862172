import React from 'react'
import { useTranslation } from "react-i18next";

import "./ObservationGallery.scss"
import ZoomImage from '../ZoomImage/ZoomImage.jsx';
import { AuthContext } from '../../context/AuthContext';

// Import Icons
import forward_icon_white from "../../assets/icons/white/forward_small.png"
import back_icon_white from "../../assets/icons/white/back_small.png"
import alert_icon from '../../assets/icons/alert.png';

export default function ObservationGallery({ observation, getNext, getPrevious }) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [flagObservation, setFlagObservation] = React.useState(false);

    const FLAGGING_ACCURACY_THRESHOLD = 0.8;

    React.useEffect(() => {
        function handleKeyDown(event) {
            /* Check keys for bale-scrolling */
            // Check for left arrow key
            if (event.keyCode === 37) {
                getPrevious(observation)
            }
    
            // Check for right arrow key
            if (event.keyCode === 39) {
                getNext(observation);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    })

    React.useEffect(() => {
        function checkFlagObservation() {
            /* Check if the observation needs to be flagged for low accuracy prediction. Only for labelers and admins */
            let flag = false;
            if (currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                "flag_low_accuracy" in currentUser.company && "predicted_labels" in observation) {
                for(const labelset of currentUser.company.flag_low_accuracy) {
                    if (labelset in observation.predicted_labels && observation.predicted_labels[labelset].cumulative_accuracy < FLAGGING_ACCURACY_THRESHOLD) {
                        flag = true;
                    }
                }
            }
            setFlagObservation(flag);
        }
        checkFlagObservation();
    });
    
    return (
        <div className="observation-gallery">
            <div className="observation-gallery--next" onClick={() => getPrevious(observation)}>
                <img className="observation-gallery--button-icon" src={back_icon_white}/>
            </div>
            <div className="observation-gallery--img-container">
                <ZoomImage image_url={observation.image_url}/>
                {flagObservation && <div className="observation-gallery--alert">
                    <img className="observation-gallery--alert-icon" src={alert_icon}/>
                    <p>{t("alert_low_accuracy")}</p>
                </div>}
            </div>
            <div className="observation-gallery--next" onClick={() => getNext(observation)}>
                <img className="observation-gallery--button-icon" src={forward_icon_white}/>
            </div>
        </div>
    )
}