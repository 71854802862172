import React from 'react'
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';
import { Link } from "react-router-dom"

import "./FullscreenObservationModal.scss"
import ObservationLabelingPanel from './ObservationLabelingPanel.jsx';
import ObservationGallery from './ObservationGallery.jsx';
import { authAndDownloadImage } from "../../utils/ImgUtils.js"

// Import Icons
import close_icon from "../../assets/icons/close.svg"
import download_icon from '../../assets/icons/white/download.png';
import share_icon from '../../assets/icons/white/share.png';
import material_flow_icon from '../../assets/icons/white/material_flow.png';

export default function FullscreenObservationModal({ observation, closeFullscreenModal, getNext, getPrevious }) {
    const { i18n, t } = useTranslation();
    const { logEventCustom } = React.useContext(AuthContext);

    function copyObservationURL() {
        /* Copy the URL of the observation to the clipboard */
        const url = `https://analytics.upcircle.ai/observation/${observation.doc_id}`;
        navigator.clipboard.writeText(url);
    }
    
    return (
        <div className="fullscreen-observation-modal">
            <div className="fullscreen-modal--center">
                <ObservationGallery
                    observation={observation}
                    getNext={getNext}
                    getPrevious={getPrevious}
                />
            </div>
            <div className="fullscreen-modal--right">
                <div className="fullscreen-modal--buttons">
                    <Link className="fullscreen-modal--button" to={`/material-flow/${observation.timestamp.toDate().toISOString()}`}>
                        <img className="fullscreen-modal--button-icon" src={material_flow_icon} title="jump to material-flow view"/>
                    </Link>
                    <div className="fullscreen-modal--button" onClick={copyObservationURL}>
                        <img className="fullscreen-modal--button-icon" src={share_icon} title="copy to clipboard"/>
                    </div>
                    <div className="fullscreen-modal--button" onClick={() => {
                        authAndDownloadImage(observation.image_url, `observation_${observation.date}_${observation.time}.jpg`);
                        logEventCustom('download_observation', {content_id: observation.doc_id});
                        }}>
                        <img className="fullscreen-modal--button-icon" src={download_icon} title="download"/>
                    </div>
                    <div className="fullscreen-modal--button" onClick={closeFullscreenModal}>
                        <img className="fullscreen-modal--button-icon" src={close_icon}/>
                    </div>
                </div>
                <ObservationLabelingPanel
                    observation={observation}
                    getNext={getNext}
                />
            </div>
        </div>
    )
}