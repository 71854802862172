import React from 'react'
import { useTranslation } from "react-i18next";

import "./BaleLabelingPanel.scss"
import { AuthContext } from '../../context/AuthContext.js';
import { firestore } from '../../context/FirebaseConfig.js';
import { doc } from 'firebase/firestore'

import LabelingDropdowns from '../LabelingComponents/LabelingDropdowns.jsx';
import LabelingMotivationalPanel from '../LabelingComponents/LabelingMotivationalPanel.jsx';

export default function BaleLabelingPanel({ baleId, deliveryId, bale, getNext }) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);

    return (
        <div className="labeling-panel">
            { /* Show predicted classes to people with flags */ }
            {currentUser.info.user_flags && ['labeler', 'admin', 'manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
            Object.keys(bale?.predicted_labels || {}).sort().map(labelset => {
                const predictedClass = bale?.predicted_labels[labelset]?.class;
                if (!predictedClass || !labelsets?.[labelset]?.name) return;
                const predictedClassName = labelsets[labelset]?.['labels']?.[predictedClass]?.display_name ?? predictedClass;
                const accuracyFraction = bale.predicted_labels[labelset]?.cumulative_accuracy ?? 100;
                const accuracy = Math.round(accuracyFraction*100);
                return <p className="predicted-recipe-material" key={labelset}>{`${labelsets[labelset].name}: ${predictedClassName} (${accuracy}%)`}</p>
            })}

            { /* Show dropdowns for labeling */ }
            {currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) &&
            <LabelingDropdowns 
                labelItem={bale}
                docRef={doc(firestore, "clients", currentUser.company.company, "deliveries", deliveryId, "bales", baleId)}
                getNext={getNext}
            />
            }

            { /* Motivation Messages */}
            {currentUser.info.user_flags && ['labeler', 'admin'].some(flag => currentUser.info.user_flags.includes(flag)) && 
            <LabelingMotivationalPanel />
            }
        </div>
    )
}