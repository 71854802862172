import React from 'react'
import { useTranslation } from "react-i18next";

import "./LabelingDropdowns.scss"
import { AuthContext } from '../../context/AuthContext.js';
import { firestore } from "../../context/FirebaseConfig";
import { updateDoc, query, collection, getDocs, where } from 'firebase/firestore'
import Select from 'react-select';
import QualitySliderDropdown from '../QualitySliderDropdown/QualitySliderDropdown.jsx';

export default function LabelingDropdowns({ labelItem, docRef, getNext }) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets } = React.useContext(AuthContext);
    const [selectedLabels, setSelectedLabels] = React.useState({});
    const [labelOptions, setLabelOptions] = React.useState({});
    const [multiLabelOptions, setMultiLabelOptions] = React.useState({});
    const [requestLabel, setRequestLabel] = React.useState(false);
    const [users, setUsers] = React.useState({});

    React.useEffect(() => {
        /* Load labels for the labelItem and build select-dropdowns */
        if (!labelItem) {
            return;
        }

        const selectedLabelsNew = {};
        if (labelItem.labels) {
            Object.keys(labelsets).forEach(labelsetId => {
                if (labelsetId in labelItem.labels && currentUser.uid in labelItem.labels[labelsetId]) {
                    const labelsetType = labelsets[labelsetId].type;
                    if (labelsetType === 'multiple-choice') {
                        selectedLabelsNew[labelsetId] = [];
                        labelItem.labels[labelsetId][currentUser.uid].forEach(id => {
                            if (id) selectedLabelsNew[labelsetId].push({value: id, label: labelsets[labelsetId].labels[id]?.full_name});
                        });
                    } else {
                        const id = labelItem.labels[labelsetId][currentUser.uid];
                        if (!id) return;
                        const value = labelItem.labels[labelsetId][currentUser.uid]
                        const label = labelsets[labelsetId].labels[id]?.full_name;

                        if (labelsetType === 'single-choice-with-slider') {
                            const continuous_value = 
                                labelItem?.labels_continuous?.[labelsetId]?.[currentUser.uid] ?? // Use continuous value if available
                                labelsets[labelsetId]?.labels?.[id]?.continuous_value // If not use default value associated with label
                            selectedLabelsNew[labelsetId] = {value, label, continuous_value};
                        } else {
                            selectedLabelsNew[labelsetId] = {value, label};
                        }        
                    }
                } else {
                    selectedLabelsNew[labelsetId] = [];
                }
            });
        } else {
            Object.keys(labelsets).forEach(id => {
                if (labelsets[id]?.type === "multiple-choice") {
                    selectedLabelsNew[id] = [];
                } else {
                    selectedLabelsNew[id] = "";
                }
            });
        }
        setSelectedLabels(selectedLabelsNew);
        buildLabelOptions();
        setRequestLabel(labelItem?.request_label ?? false);
    }, [labelItem])

    async function buildLabelOptions() {
        /* Build the options lists for the select-dropdowns. One for each labelset */
        const labelOptionsNew = {};
        const multiLabelOptionsNew = {};

        Object.keys(labelsets).forEach(id => {
            if (labelsets[id]?.enable_labelling) {
                if (labelsets[id]?.type === "multiple-choice") {
                    multiLabelOptionsNew[id] = [];
                    Object.keys(labelsets[id].labels).forEach(labelId => {
                        multiLabelOptionsNew[id].push({"value":labelId, "label":labelsets[id].labels[labelId].full_name});
                    });
                } else if (labelsets[id]?.type === "single-choice-with-slider") {
                    labelOptionsNew[id] = getGroupedLabelsList(id);
                    labelOptionsNew[id] = addContinuousValuesToLabels(labelOptionsNew[id], id);
                } else {
                    labelOptionsNew[id] = getGroupedLabelsList(id);
                }
            }
        });

        setLabelOptions(labelOptionsNew);
        setMultiLabelOptions(multiLabelOptionsNew);
    }

    function getGroupedLabelsList(id) {
        /** 
         * Create different groups for labels in order to facilitate labeling
         * First group: Predicted -> put predicted labels on top and highlight
         * Second group: Closely Related -> put labels closesly related to predictions second
         * Third group: All others in same product group
         */
        if (!("predicted_labels" in labelItem)) {
            // If there are no predictions for the labelset, then just return as is.
            return Object.keys(labelsets[id].labels).sort().map(labelId => {
                return {"value":labelId, "label":labelsets[id].labels[labelId].full_name}
            });
        } else {
            const labelOptionsGrouped = [];
            const labelsFilteredRemaining = {...labelsets[id].labels};

            // Put predicted labels in recommended list
            let predictedLabelIds = [];
            if (id in labelItem.predicted_labels) {
                predictedLabelIds.push(labelItem.predicted_labels[id].class);
            } else {
                predictedLabelIds = getMappedLabelIds(id);
            }
            const recommendedLabelOptions = [];
            for (const predictedLabelId of predictedLabelIds) {
                recommendedLabelOptions.push({
                    "value": predictedLabelId, 
                    "label": labelsets[id].labels[predictedLabelId]?.full_name,
                    "group": "predicted",
                })
                delete labelsFilteredRemaining[predictedLabelId];
            }

            // Put closely related labels in recommended list
            for (const predictedLabelId of predictedLabelIds) {
                if (predictedLabelId in labelsets[id].labels && "close_relations" in labelsets[id].labels[predictedLabelId]) {
                    labelsets[id].labels[predictedLabelId].close_relations.forEach(closeLabelId => {
                        if (closeLabelId in labelsFilteredRemaining) {
                            recommendedLabelOptions.push({
                                "value": closeLabelId, 
                                "label": labelsets[id].labels[closeLabelId].full_name,
                                "group": "related",
                            })
                            delete labelsFilteredRemaining[closeLabelId];
                        }
                    })
                }
            }
            if (recommendedLabelOptions?.length > 0) {
                labelOptionsGrouped.push({
                    "label": t("labeling_select_recommended"),
                    "options": recommendedLabelOptions,
                    "group": "recommended",
                });
            }

            // Add all further labels belonging to the same product_group
            const remainingLabelOptions = []
            Object.keys(labelsFilteredRemaining).sort().forEach(labelId => {
                if ("product_group" in labelsFilteredRemaining[labelId] 
                    && "object_type" in labelItem 
                    && labelsFilteredRemaining[labelId].product_group != labelItem.object_type) {
                    return;
                }
                remainingLabelOptions.push({"value":labelId, "label":labelsets[id].labels[labelId].full_name})
            });
            if (remainingLabelOptions?.length > 0) {
                labelOptionsGrouped.push({
                    'label': t("labeling_select_others"),
                    'options': remainingLabelOptions,
                });
            }

            if (labelOptionsGrouped.length === 1) {
                // No need for grouping if there is only one group
                return labelOptionsGrouped[0].options;
            } else {
                return labelOptionsGrouped;
            }
        }
    }

    function getMappedLabelIds(labelsetId) {
        /* Select which labels are predicted based on predictions for a labelset with mapping */
        if ("use_mapping" in labelsets[labelsetId] && labelsets[labelsetId].use_mapping) {
            if (labelsets[labelsetId].use_mapping in labelItem.predicted_labels) {
                // Predictions for mapped labelset available
                const mappedLabelsetId = labelsets[labelsetId].use_mapping;
                if ("mappings" in labelsets[mappedLabelsetId] && labelsetId in labelsets[mappedLabelsetId]["mappings"] 
                && labelItem.predicted_labels[mappedLabelsetId]["class"] in labelsets[mappedLabelsetId]["mappings"][labelsetId]) {
                    // Return mapped prediction
                    return labelsets[mappedLabelsetId]["mappings"][labelsetId][labelItem.predicted_labels[mappedLabelsetId]["class"]];
                } else {
                    // The predicted label in the mapped labelset has no mapping in the target labelset
                    return [];
                }
            } else {
                // No predictions for mapped labelset either -> Check for higher order mapping in recursive call
                const labelIds = getMappedLabelIds(labelsets[labelsetId].use_mapping);
                let mappedLabelIds = [];
                const mappedLabelsetId = labelsets[labelsetId].use_mapping;
                for (const labelId of labelIds) {
                    mappedLabelIds = [...mappedLabelIds, 
                        ...labelsets[mappedLabelsetId]["mappings"][labelsetId][labelId]]
                }
                return mappedLabelIds;
            }
        } else {
            // No mapping available, therefore no predictions found
            return [];
        }
    }

    function addContinuousValuesToLabels(labelOptions, labelsetId) {
        /* Add the continuous_value to label options of a labelset of type single-choice-with-slider */
        const labelOptionsWithContinuousValues = labelOptions.map(option => {
            return {
                ...option,
                continuous_value: labelsets?.[labelsetId]?.labels?.[option.value]?.continuous_value ?? 0,
            }
        });
        return labelOptionsWithContinuousValues;
    }

    React.useEffect(() => {
        /* Load users in the company (only admins since it is used to display the labelers) */
        async function getUsers() {
            /* Load all users in the company */
            const usersQuery = query(collection(firestore, "users"), where("company", "==", currentUser.company.company));
            const usersSnapshot = await getDocs(usersQuery);
            const users = {};
            usersSnapshot.forEach(doc => {
                users[doc.id] = doc.data();
            });
            setUsers(users);
        }

        if (currentUser.info.user_flags.includes('admin')) {
            getUsers();
        }
    }, []);

    async function onLabelChange(labelsetId, selectedLabel) {
        /* Handle when labels are selected/deselected in the dropdowns. Id signifies for which labelset the selection happened */
        setSelectedLabels(prevSelectedLabels => {
            return {...prevSelectedLabels, [labelsetId]: selectedLabel};
        });

        const updateDict = {
            'labelled': true,
            'request_label': false,
        }

        // Write main label
        const label = labelsets[labelsetId]?.type === "multiple-choice" ? 
            selectedLabel.map(option => option?.value ?? "") : 
            selectedLabel?.value ?? "";
        updateDict[`labels.${labelsetId}.${currentUser.uid}`] = label;

        // Write continuous label if available
        if (labelsets[labelsetId]?.type === "single-choice-with-slider") {
            updateDict[`labels_continuous.${labelsetId}.${currentUser.uid}`] = selectedLabel?.continuous_value;
        }

        if (docRef) {
            updateDoc(docRef, updateDict);
        }
    }

    async function onSkipLabel() {
        updateDoc(docRef, { 'labelled':true });
        getNext(labelItem);
    }

    function onRequestLabel() {
        /* Handle when the checkbox to request labeling for an observation is changed */
        if (docRef) {
            updateDoc(docRef, {
                'request_label': !requestLabel,
            });
            setRequestLabel(prev => !prev);
        }
    }

    return (
        <div className="labeling-dropdowns">
            { /* Single-Choice Labelling Dropdowns */}
            {Object.keys(labelOptions)
            .sort((a,b) => {return labelsets[a]?.sorting_index-labelsets[b]?.sorting_index})
            .map(id => {
                if (labelOptions[id].length === 0) {
                    // No labels available for this product-group
                    return;
                }
                return <div key={id}>
                    <p className="material-label-title">{labelsets[id].name}:</p>
                    {labelsets[id].type === "single-choice-with-slider" ?
                    // Return dropdown with slider for single-choice-with-slider labelsets
                    <QualitySliderDropdown 
                        labelOptions={labelOptions[id]}
                        selectedLabel={selectedLabels[id]}
                        onLabelChange={(e) => {onLabelChange(id, e)}}
                        showDropdown={labelsets[id].show_dropdown ?? true}
                    /> :
                    // Return normal dropdown for single-choice labelsets
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        options={labelOptions[id]}
                        value={selectedLabels[id]}
                        onChange={(e) => {onLabelChange(id, e)}}
                        className="material-label-select"
                        styles={{
                            option: (base, state) => ({
                                ...base,
                                padding: '0.2rem 0rem 0.2rem 0.5rem',
                                backgroundColor: state.data.group === "predicted" ? "#b8f5cc" : state.data.group === "related" ? "#dcfce7" : base.backgroundColor,
                                ':hover': {
                                    ...base[':hover'],
                                    backgroundColor: "#b5d4ff",
                                },
                            }),
                        }}
                    />}
                    {currentUser.info.user_flags.includes('admin') && labelItem.labels && labelItem.labels[id] && Object.keys(labelItem.labels[id]).map(key => {
                        const labelId = labelItem.labels[id][key];
                        if (!labelId) return;
                        const userName = users[key]?.displayName ?? key;
                        return <p key={key} className="material-label--annotations">{labelsets[id].labels[labelId].display_name} - {userName}</p>
                    })}
                </div>
            })}
            { /* Multiple-Choice Labelling Dropdowns */}
            {Object.keys(multiLabelOptions).map(id => {
                return <div key={id}>
                    <p className="material-label-title">{labelsets[id].name}:</p>
                    <Select
                        isMulti
                        options={multiLabelOptions[id]}
                        value={selectedLabels[id]}
                        onChange={(e) => {onLabelChange(id, e)}}
                        className="material-label-select"
                        styles={{
                            option: (base) => ({
                                ...base,
                                padding: '0.2rem 0rem 0.2rem 0.5rem',
                            }),
                        }}
                    />

                    {/*<MultiSelectDropdown selectedOptions={selectedLabels[id]} options={multiLabelOptions[id]} onChange={(e) => {onLabelChange(id, e)}}/>*/}
                    {currentUser.info.user_flags.includes('admin') && labelItem.labels && labelItem.labels[id] && Object.keys(labelItem.labels[id]).map(key => {
                        const labelIds = labelItem.labels[id][key];
                        if (!labelIds || labelIds.length===0) return;
                        return <p className="material-label--annotations">{labelIds.reduce((acc, labelId) => `${acc}${labelsets[id].labels[labelId].display_name}, `, "")}</p>
                    })}
                </div>
            })}
            { /* Skip-button */}
            <button className="material-label--button" onClick={onSkipLabel}>{t("labeling_skip")}</button>
            { /* Request label checkbox */}
            {currentUser.info.user_flags && ['admin'].some(flag => currentUser.info.user_flags.includes(flag)) && 
            <div className="request-label-container"><input
                type="checkbox"
                checked={requestLabel}
                onChange={onRequestLabel}
            />
            <label>{t("labeling_request")}</label></div>}
        </div>
    )
}