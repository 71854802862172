import "./css/Card.scss"

import BasicCard from './BasicCard'
import TableCard from './TableCard'
import TimelineCard from './TimelineCard/TimelineCard'
import PiechartCard from "./PiechartCard"
import DayReviewCard from "./DayReviewCard/DayReviewCard"
import DaySummaryCard from "./DaySummaryCard/DaySummaryCard"
import DeviceStatusCard from "./DeviceStatusCard/DeviceStatusCard"
import LastObservationCard from "./LastObservationsCard/LastObservationCard"
import MultiTimelineCard from "./TimelineCard/MultiTimelineCard"
import DeliveriesTableCard from "./DeliveriesTableCard/DeliveriesTableCard"
import DeliveryCard from "./DeliveryCard/DeliveryCard"

export default function Card({ cardData, payload, cardClassName }) {
    return (
        <div className={`card ${cardClassName}`}>
            <div className="card_header">
                <div className="iconWrapper">
                    {cardData.icon}
                </div>
                <span className="title">{cardData.title}</span>
                <span className="timeframe">{cardData.subtitle}</span>
            </div>
            {
                cardData === null ? 'loading .. ':
                {
                    'BasicCard': <BasicCard cardData={cardData} />,
                    'TableCard': <TableCard cardData={cardData} payload={payload} />,
                    'TimelineCard': <TimelineCard cardData={cardData} payload={payload} />,
                    'MultiTimelineCard': <MultiTimelineCard cardData={cardData} payload={payload} />,
                    'PiechartCard': <PiechartCard cardData={cardData} payload={payload}/>,
                    'DayReviewCard': <DayReviewCard cardData={cardData} payload={payload}/>,
                    'DaySummaryCard': <DaySummaryCard cardData={cardData} payload={payload}/>,
                    'DeviceStatusCard': <DeviceStatusCard cardData={cardData}/>,
                    'LastObservationCard': <LastObservationCard cardData={cardData} payload={payload}/>,
                    'DeliveriesTableCard': <DeliveriesTableCard cardData={cardData} payload={payload}/>,
                    'DeliveryCard': <DeliveryCard cardData={cardData} payload={payload}/>,
                }[cardData.ctype]
            }
        </div>
    )
  }