import "./Overview.scss"
import React from 'react';
import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { startOfWeek, endOfWeek } from "date-fns";
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";

// Importing custom pages
import Navbar from '../../components/Navbar/Navbar';
import Insights from './Insights';
import Sidebar from '../../components/Navbar/Sidebar';
import LoadingScreen from "../../components/LoadingScreen";
import TimeframeFilter from "../../components/Filters/TimeframeFilter";
import FullscreenObservationModal from "../../components/FullscreenObservationModal/FullscreenObservationModal";

export default function Overview() {
    const { i18n, t } = useTranslation();
    const { observationId } = useParams();

    const [ timeframe, setTimeframe ] = React.useState({startDate: startOfWeek(new Date()), endDate: endOfWeek(new Date()), label: "timeframe_this_week"});
    const { currentUser } = React.useContext(AuthContext);
    const [ showObservation, setShowObservation ] = React.useState(null);

    React.useEffect(() => {
        async function getObservation() {
            const observationDoc = await getDoc(doc(firestore, "clients", currentUser.company.company, "observations", observationId));
            if (observationDoc.exists) {
                setShowObservation({...observationDoc.data(), doc_id: observationDoc.id});
            }
        }
        
        if (observationId) {
            getObservation();
        }
    }, [observationId]);

    return (
        <div className="overview">
            <Sidebar />
            <div className="overview--right">
                { currentUser.company && <Navbar 
                    title={`${currentUser.company.company_displayname} ${t("sidebar_dashboard")}`}
                />}
                <TimeframeFilter 
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                />
                { currentUser.device_id ? 
                    <Insights 
                        timeframe={timeframe}
                    /> :
                    <LoadingScreen loading={t("loading")} fullscreen={false} message={t("device_not_found_error")} logoColor="dark"/>                    
                }
                { showObservation && <FullscreenObservationModal 
                observation={showObservation}
                closeFullscreenModal={() => setShowObservation(null)}
                getNext={() => {}}
                getPrevious={() => {}}
            /> }
            </div>
        </div>
    )
}