import React from 'react';
import './ConfirmationMessage.scss';

export default function ConfirmationMessage({ message, show, setShow }) {
    const MESSAGE_FADE_TIMEOUT = 5000;

    /* the message will be hidden after MESSAGE_FADE_TIMEOUT milliseconds */
    React.useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setShow(false);
            }, MESSAGE_FADE_TIMEOUT);

            return () => clearTimeout(timer);
        }
    }, [show]);
    
    if (show) {
        return (
            <div className="confirmation-message">
                <p>{message}</p>
            </div>
        );
    } else {
        return null;
    }
};
