import React from 'react';
import Select from 'react-select';
import { Slider } from '@mui/material';

import "./QualitySliderDropdown.scss";

export default function QualitySliderDropdown({ labelOptions, selectedLabel, onLabelChange, showDropdown = true }) {
    const [selectedLabelDisplay, setSelectedLabelDisplay] = React.useState(selectedLabel);

    React.useEffect(() => {
        setSelectedLabelDisplay(selectedLabel);
    }, [selectedLabel]);

    // Handle dropdown change
    function handleSelectChange(event) {
        /* When the dropdown is changed, update the selected label on the DB */
        onLabelChange(event);
    };

    // Handle slider change
    function handleSliderChange(event, newValue) {
        /* When the slider is moved, update the selected label display without pushing to DB */
        const label = getLabelFromSliderValue(newValue);
        setSelectedLabelDisplay(label);
    };

    function handleSliderChangeCommitted (event, newValue) {
        /* When the slider is released, update the selected label on the DB */
        const label = getLabelFromSliderValue(newValue);
        onLabelChange(label);
    };

    function getLabelFromSliderValue(sliderValue) {
        /* Create a label object for the current slider value. Will pick the closest label from the labelOptions array. */
        const nearestLabel = labelOptions.reduce((prev, curr) => {
            return Math.abs(curr.continuous_value - sliderValue) < Math.abs(prev.continuous_value - sliderValue) ? curr : prev;
        });
        const label = {...nearestLabel, continuous_value: sliderValue};
        return label;
    }

    return (
        <div className="quality-slider-dropdown">
            {showDropdown && <Select
                isClearable={true}
                isSearchable={true}
                options={labelOptions}
                value={selectedLabelDisplay}
                onChange={handleSelectChange}
                className="material-label-select"
                styles={{
                    option: (base, state) => ({
                        ...base,
                        padding: '0.2rem 0rem 0.2rem 0.5rem',
                        backgroundColor: state.data.group === "predicted" ? "#b8f5cc" : state.data.group === "related" ? "#dcfce7" : base.backgroundColor,
                        ':hover': {
                            ...base[':hover'],
                            backgroundColor: "#b5d4ff",
                        },
                    }),
                }}
            />}
            <Slider
                className={`quality-slider ${selectedLabelDisplay?.continuous_value === undefined ? "disabled" : ""}`}
                value={selectedLabelDisplay?.continuous_value ?? 0.5}
                min={0}
                max={1}
                step={0.01}
                marks={labelOptions.map(option => ({...option, value: option.continuous_value}))}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeCommitted}
                valueLabelDisplay="off"
            />
        </div>
    );
}