import React from "react";
import "./DeliveriesTableCard.scss";

import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useTable } from 'react-table'
import { format } from "date-fns";

// Importing logos
import photo_icon       from "../../../assets/icons/gray/image.png";
import notes_icon       from '../../../assets/icons/gray/notes.png';
import bales_icon       from '../../../assets/icons/lightblue/bales_stacked.png';
import warning_icon     from '../../../assets/icons/alert.png';
import label_icon       from '../../../assets/icons/label_green.png';

export default function DeliveriesTableCard({cardData, payload}) {
    const { i18n, t } = useTranslation();
    const { currentUser, labelsets, logEventCustom } = React.useContext(AuthContext);

    const COLUMNS = [
        {
            Header: " ",
            accessor: (row) => {
                return (
                    <div className="table-icon-wrapper">
                        {row?.reclamation_data?.reclamation_status === 'confirmed' && <img src={warning_icon}/>}
                        {row.notes!=="" && <img src={notes_icon}/>}
                    </div>
                )
            },
            id: "flagged"
        },
        {
            Header: t("property_date"),
            accessor: (row) => {return format(row.date, "dd.MM.yyyy - HH:mm")}
        },
        {
            Header: t("property_supplier"),
            accessor: (row) => {
                return labelsets?.supplier?.labels?.[row.supplier]?.display_name ?? row.supplier;
            }
        },
        {
            Header: t("property_material"),
            accessor: (row) => {
                return labelsets?.['materials']?.['labels']?.[row?.base_labels?.materials]?.display_name ?? "";
            }
        },
        {
            Header: t("property_photos"),
            accessor: (row) => {
                let photoCount = 0;
                if (row?.delivery_images) {
                    photoCount = Object.keys(row.delivery_images).length;
                }

                return(<div className="photo-property">
                    <p>{photoCount}</p>
                    <img src={photo_icon}/>
                </div>)
            }
        },
        {
            Header: t("property_registered_bales"),
            accessor: (row) => {
                let baleCount = 0;
                if (row?.bales) {
                    baleCount = Object.keys(row.bales).length;
                }
                return(<div className="photo-property">
                    <p>{baleCount}</p>
                    <img src={bales_icon}/>
                </div>)
            }
        },
    ];
    if (currentUser.info.user_flags && ['admin', 'labeler'].some(flag => currentUser.info.user_flags.includes(flag))) {
        COLUMNS.push({
            Header: "Labels",
            accessor: (row) => {
                const labelCount = Object.values(row?.bales ?? {}).reduce((acc, bale) => acc += bale?.labelled ? 1 : 0, 0);
                const labelPercentage = Math.floor(labelCount / Object.keys(row?.bales ?? {}).length * 100);
                if (labelCount == 0) return "";
                return(
                    <div className="photo-property">
                        <p>{labelPercentage}%</p>
                        <img src={label_icon}/>
                    </div>
                )
            }
        })
    }
    const columns = React.useMemo(() => COLUMNS, [labelsets['materials'], payload?.deliveriesSettings, i18n.language]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: Object.values(payload.deliveries).reverse() })

    // If there are no deliveries, display a message
    if (!payload.deliveries || Object.keys(payload.deliveries).length === 0) {
        return (
            <div className="delivieries-table-card-body">
                <p>{t("no_deliveries")}</p>
            </div>
        )
    }

    return (
        <div className="deliveries-table-card-body">
            <div className="table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} 
                                    onClick={() => {
                                        payload.setActiveDelivery(row.original.id);
                                        logEventCustom("select_content", {content_type: "delivery", content_id: row.original.id});
                                    }} 
                                    className={row.original.id==payload.activeDelivery ? "active-row" : undefined}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}