import React, { useState } from 'react';
import '../AdminSettingsModal.scss';
import ConfirmationModal from '../ConfirmationModal.jsx';
import ConfirmationMessage from '../../../components/ConfirmationMessage/ConfirmationMessage.jsx';

import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { firestore } from '../../../context/FirebaseConfig';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { format } from "date-fns";

import close_icon from "../../../assets/icons/close_black.svg"
import parameters_icon from "../../../assets/icons/gray/parameters.png"
import settings_icon from "../../../assets/icons/gray/settings.png"
import reboot_icon from "../../../assets/icons/gray/refresh.png"
import DeviceParameters from './DeviceParameters';

const DeviceModal = ({ device, selectedClient, clients, handleClose }) => {
    const { i18n, t } = useTranslation();
    const { parametersConfig } = React.useContext(AuthContext);
    const [isNewDevice, setIsNewDevice] = useState(true);
    const [deviceId, setDeviceId] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [company, setCompany] = useState('');
    const [displayLocation, setDisplayLocation] = useState('');
    const [deviceDescription, setDeviceDescription] = useState('');
    const [localIp, setLocalIp] = useState('');
    const [emails, setEmails] = useState([]);
    const [filterObservationClasses, setFilterObservationClasses] = useState([]);
    const [password, setPassword] = useState('');
    const [deviceParameters, setDeviceParameters] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [editParameters, setEditParameters] = useState(false);
    const [showRebootConfirmationModal, setShowRebootConfirmationModal] = useState(false);
    const [showConfirmSaveMessage, setShowConfirmSaveMessage] = useState(false);

    React.useEffect(() => {
        setIsNewDevice(device ? false : true);
        if (device) {
            setDeviceId(device.device_id);
            setDisplayName(device.display_name);
            setCompany(device.client);
            setDisplayLocation(device.display_location);
            setDeviceDescription(device.description);
            setLocalIp(device.local_ip);
            setDeviceParameters(device?.device_parameters);
            if (device.message_on_error) {
                setEmails(device.message_on_error);
            }
            if (device.filter_observation_classes) {
                setFilterObservationClasses(device.filter_observation_classes);
            }
        } else {
            setCompany(selectedClient.id);
            setDeviceParameters(extractDefaultParameters(parametersConfig));
        }
    }, [device])

    function extractDefaultParameters(config) {
        /* Extract the default parameters from the parameters object */
        const defaultParameters = {};
      
        Object.keys(config).forEach(key => {
          const item = config[key];
      
          if (item.type === 'group') {
            defaultParameters[key] = extractDefaultParameters(item.children);
          } else if (item.hasOwnProperty('default_value')) {
            defaultParameters[key] = item.default_value;
          }
        });
      
        return defaultParameters;
    }

    function handleEmailsChange(index, newValue) {
        /* Change the email address at index */
        const newEmails = emails.map((email, attrIndex) => {
            if (index === attrIndex) {
                return newValue;
            }
            return email;
        });
        setEmails(newEmails);
    };

    function handleAddEmail() {
        /* Add a new email to the notification list */
        setEmails((prevEmails) => { return [...prevEmails, ''] });
    };

    function handleFOCChange(index, newValue) {
        /* Change the value of the observation-class-filter at index */
        const newFilterObservationClasses = filterObservationClasses.map((filterObservationClass, attrIndex) => {
            if (index === attrIndex) {
                return newValue;
            }
            return filterObservationClass;
        });
        setFilterObservationClasses(newFilterObservationClasses);
    };

    function handleAddFOC() {
        /* Add a new filter observation class to the list */
        setFilterObservationClasses((prevFilterObservationClasses) => { return [...prevFilterObservationClasses, ''] });
    };

    async function deviceIdExists() {
        /* Check if the given device id already exists in the database */
        const docSnap = await getDoc(doc(firestore, "clients", company, "devices", deviceId));
        return docSnap.exists();
    }

    async function handleSubmit() {
        /* Submit the form and update database, either by creating or updating device info */
        // Check if all necessary fields are filled out for a valid submission
        if (!deviceId || !company || !displayLocation || (isNewDevice && password.length < 6)) {
            setErrorMessage("All fields must be filled out.");
            return;
        }

        const deviceUpdate = {
            device_id: deviceId,
            display_name: displayName,
            client: company,
            display_location: displayLocation,
            description: deviceDescription,
            local_ip: localIp,
            message_on_error: emails.filter((email) => { return email != '' }),
            filter_observation_classes: filterObservationClasses.filter((filterClass) => { return filterClass != '' }),
            device_parameters: deviceParameters ?? {},
        }

        if (isNewDevice) {
            // If it is a new device, check if the device id already exists
            if (await deviceIdExists()) {
                setErrorMessage("Given Device-Id already exists!");
                return;
            }

            // Create a new user with device flag for the credentials of the new device
            await createDeviceUser();
        } else if (password && password.length >= 6) {
            // If it is an existing user and a new password was entered, update the password using admin-call
            await setDoc(doc(firestore, "admin_function_calls", "function_types", "change_user", format(new Date(), "yyyy-MM-dd_HH-mm-ss")), {
                uid: device.uid,
                userChanges: { password: password },
            });
        }

        await setDoc(doc(firestore, "clients", company, "devices", deviceId), deviceUpdate, { merge: true });

        setShowConfirmSaveMessage(true);
    };

    async function createDeviceUser() {
        /* Create a user with device flag for the credentials of the new device */
        // Imaginary email with upcircle-devices.ai domain
        const email = `${deviceId}@upcircle-devices.ai`;

        // Create user document for database
        const newUser = {
            displayName: deviceId,
            email: email,
            languageCode: 'en',
            company: company,
            user_flags: ['device'],
            photoURL: "",
            status: "active",
            createdAt: serverTimestamp(),
        };    

        // Create user with the following admin-call -> Creates user doc + user credentials
        await setDoc(doc(firestore, "admin_function_calls", "function_types", "create_user", format(new Date(), "yyyy-MM-dd_HH-mm-ss")), {
            email: email,
            password: password,
            displayName: deviceId,
            userDoc: newUser,
        });
    }

    function handleRebootDevice() {
        /* Set the reboot-request flag for the device to be picked up by firebase manager */
        setDoc(doc(firestore, "clients", company, "devices", deviceId), { device_parameters: {request_reboot: true} }, { merge: true });
        setShowRebootConfirmationModal(false);
        handleClose();
    }

    return (
        <div className="admin-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{isNewDevice ? t("settings_create_new_device") : t("settings_edit_device")}</h2>
                    <div className="modal-close-button" onClick={handleClose}>
                        <img className="modal-close-button-icon" src={close_icon} />
                    </div>
                </div>

                {!editParameters && <div className="modal-center">
                    <div className="label-container">
                        <label>{t("settings_device_id")}:</label>
                        {isNewDevice ? <input type="text" value={deviceId} onChange={(e) => setDeviceId(e.target.value)} /> : <p>{deviceId}</p>}
                    </div>

                    <div className="label-container">
                        <label>{t("settings_device_company")}:</label>
                        {isNewDevice ? <select value={company} onChange={(e) => setCompany(e.target.value)}>
                            {Object.keys(clients).map(id => {
                                return <option key={id} value={id}>{clients[id].company_displayname}</option>
                            })}
                        </select> :
                            <p>{clients[company].company_displayname}</p>}
                    </div>

                    <div className="label-container">
                        <label>{t("settings_device_display_name")}:</label>
                        <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    </div>

                    <div className="label-container">
                        <label>{t("settings_device_location_name")}:</label>
                        <input type="text" value={displayLocation} onChange={(e) => setDisplayLocation(e.target.value)} />
                    </div>

                    <div className="label-container">
                        <label>{t("settings_device_description")}:</label>
                        <input type="text" value={deviceDescription} onChange={(e) => setDeviceDescription(e.target.value)} />
                    </div>

                    <div className="label-container">
                        <label>{t("settings_device_local_ip")}:</label>
                        <input type="text" value={localIp} onChange={(e) => setLocalIp(e.target.value)} />
                    </div>

                    <div className="label-container">
                        <label>{t("settings_user_password")}:</label>
                        <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div className="admin-settings-array">
                        <label>{t("settings_device_emails")}:</label>
                        <div className="admin-settings-array-fields">
                            {emails.map((email, index) => (
                                <input type="text" key={index} value={email} onChange={(e) => handleEmailsChange(index, e.target.value)} />
                            ))}
                            <button className="settings-modal-button light-button" onClick={handleAddEmail}>{t("settings_device_add_email")}</button>
                        </div>
                    </div>

                    <div className="admin-settings-array admin-settings-array-no-top-border">
                        <label>{t("settings_device_filters")}:</label>
                        <div className="admin-settings-array-fields">
                            {filterObservationClasses.map((filterClass, index) => (
                                <input type="text" key={index} value={filterClass} onChange={(e) => handleFOCChange(index, e.target.value)} />
                            ))}
                            <button className="settings-modal-button light-button" onClick={handleAddFOC}>{t("settings_device_add_filter")}</button>
                        </div>
                    </div>
                </div>}
                {editParameters && <div className="modal-center"><DeviceParameters 
                    deviceParameters={deviceParameters} 
                    setDeviceParameters={setDeviceParameters}
                /></div>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <div className="modal-footer">
                    <div className="settings-modal-img-button light-button" onClick={() => setShowRebootConfirmationModal(true)}>
                        <img className="button-img" src={reboot_icon} title="reboot pipeline" />
                    </div>
                    <div className="save-delete-buttons">
                        <button className="settings-modal-button light-button" onClick={handleSubmit}>{t("settings_save")}</button>
                        {/*<button className="settings-modal-button delete-button" onClick={() => {}}>Delete</button>*/}
                    </div>
                    <div className="settings-modal-img-button light-button" onClick={() => setEditParameters(prev => !prev)}>
                        <img className="button-img" src={editParameters ? settings_icon : parameters_icon} title="edit device parameters" />
                    </div>
                </div>
            </div>

            {showRebootConfirmationModal && <ConfirmationModal 
                title={t("settings_device_confirm_reboot")}
                message={t("settings_device_confirm_reboot")}
                handleSubmit={handleRebootDevice}
                handleClose={() => setShowRebootConfirmationModal(false)}
            />}

            <ConfirmationMessage message={t("settings_device_confirm_save")} show={showConfirmSaveMessage} setShow={setShowConfirmSaveMessage} />
        </div>
    );
};

export default DeviceModal;
