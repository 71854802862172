import { useTranslation } from "react-i18next";
import "./Navbar.scss"
import { Link } from "react-router-dom"
import React, { useContext } from 'react';  

// Importing icons
import uc_logo from '../../assets/logos/logo_uc_technology_ltd_S.png'
import dashboard_icon from '../../assets/icons/gray/dashboard.svg';
import live_icon from '../../assets/icons/gray/live.svg';
import settings_icon from '../../assets/icons/gray/settings.png';
import labeling_icon from '../../assets/icons/gray/labelling.png';
import bale_icon from '../../assets/icons/gray/bale_analysis.png';
import truck_icon from '../../assets/icons/gray/truck.png';
import material_flow_icon from '../../assets/icons/gray/material_flow.png';
import back_icon from '../../assets/icons/gray/back_small.png';

// imports for TEST 1 //
import { AuthContext} from "../../context/AuthContext";

const Sidebar = React.forwardRef((props, ref) => {
    // translation tools
    const { i18n, t } = useTranslation();
    const [ sidebarOpen, setSidebarOpen ] = React.useState(true);

    // Below are tests for still unused navigation bars - TEST 1 //
    const { currentUser, logEventCustom } = useContext(AuthContext);

    React.useImperativeHandle(ref, () => ({
        toggleSidebar(status = null) {
            if (status !== null) {
                setSidebarOpen(status);
            } else {
                setSidebarOpen(prev => !prev);
            }
        }
    }));

    return (
        <div className={`sidebar-wrapper ${sidebarOpen ? "" : "sidebar-closed"}`}>
            <div className={`sidebar ${sidebarOpen ? "" : "sidebar-closed"}`}>
                <img src={uc_logo} className="sidebar--logo" />
                {currentUser && currentUser.info && currentUser.info.user_flags &&
                <ul className="sidebar--links">
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/overview" onClick={() => logEventCustom("page_view", {page_title: 'overview'})}>
                        <img src={dashboard_icon} alt="dashboard icon"/>
                        <p>{t("sidebar_dashboard")}</p>
                    </Link>}
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/deliveries" onClick={() => logEventCustom("page_view", {page_title: 'deliveries'})}>
                        <img src={truck_icon} alt="overview icon"/>
                        <p>{t("sidebar_deliveries")}</p>      
                    </Link>}
                    {['admin','manager'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/live-view" onClick={() => logEventCustom("page_view", {page_title: 'live-view'})}>
                        <img src={live_icon} alt="live icon"/>
                        <p>{t("sidebar_live")}</p>      
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/plant-feed" onClick={() => logEventCustom("page_view", {page_title: 'plant-feed'})}>
                        <img src={bale_icon} alt="document icon"/>
                        <p>{t("sidebar_plant_feed")}</p>      
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/material-flow" onClick={() => logEventCustom("page_view", {page_title: 'material-flow'})}>
                        <img src={material_flow_icon} alt="material flow icon"/>
                        <p>{t("sidebar_material_flow")}</p>      
                    </Link>}
                    {['admin','manager','labeler'].some(flag => currentUser.info.user_flags.includes(flag)) &&
                    <Link to="/labeling" onClick={() => logEventCustom("page_view", {page_title: 'labeling'})}>
                        <img src={labeling_icon} alt="labelling icon"/>
                        <p>{t("sidebar_labeling")}</p>
                    </Link>}
                    {currentUser.info.user_flags.includes('admin') &&
                    <Link to="/admin-settings" onClick={() => logEventCustom("page_view", {page_title: 'admin-settings'})}>
                        <img src={settings_icon} alt="settings icon"/>
                        <p>{t("sidebar_admin_settings")}</p>
                    </Link>}
                </ul>}
                <button className={`toggle-sidebar-button ${sidebarOpen ? "" : "sidebar-closed"}`} onClick={() => {setSidebarOpen(prev => !prev)}}>
                    <img className={`toggle-sidebar-button-icon ${sidebarOpen ? "" : "sidebar-closed"}`} src={back_icon}/>
                </button>
            </div>
        </div>
    )
});

export default Sidebar;