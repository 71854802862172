import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../context/AuthContext';

import './Dropdown.scss';

export default function SupplierFilter({ suppliers, setSupplierFilter }) {
    const { t } = useTranslation();
    const { logEventCustom, labelsets } = React.useContext(AuthContext);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    function handleSupplierChange(supplier) {
        setSelectedSupplier(supplier);
        setSupplierFilter(supplier);

        // Log the event to Firebase Analytics
        logEventCustom("filter", {type: "supplier", value: supplier});
    }

    return (
        <div className="dropdown">
            <div className="dropdown-splitbutton">
                <button className="dropdown-splitbutton-text">{t("supplier_filter_title")}:</button>
                <button className="dropdown-splitbutton-button">
                    {selectedSupplier ? selectedSupplier : t("material_filter_all")}
                </button>
            </div>
            <div className="dropdown-content">
                <a onClick={() => handleSupplierChange("")}>{t("material_filter_all")}</a>
                {suppliers.map(supplier => {
                    if (supplier !== undefined && supplier !== "") {
                        return <a key={supplier} onClick={() => handleSupplierChange(supplier)}>{labelsets?.supplier?.labels?.[supplier]?.display_name ?? supplier}</a>
                    }
                })}
            </div>
        </div>
    );
}