import React from 'react'

import "./LabelingMotivationalPanel.scss"
import { AuthContext } from '../../context/AuthContext';

export default function MotivationalLines({ numLabeled }) {
    const { currentUser } = React.useContext(AuthContext);

    if (!numLabeled) {
        return <></>
    }
    
    if (numLabeled >= 200) {
        return <div className="label-count-line">
            <p>{currentUser.info.displayName}, der Ballen Magier</p>
            <p className="label-count-emoji">🔮🧙‍♂️🪄</p>
        </div>
    } else if (numLabeled >= 100) {
        return <div className="label-count-line">
            <p>Hoch lebe der Ballen König</p>
            <p className="label-count-emoji">✨🫅✨</p>
        </div>
    } else if (numLabeled >= 50) {
        return <div className="label-count-line">
            <p>You are on</p>
            <p className="label-count-emoji">🔥</p>
            <p>!</p>
        </div>
    } else if (numLabeled >= 25) {
        return <div className="label-count-line">
            <p className="label-count-emoji">🌊</p>
            <p>Du nimmst richtig fahrt auf!</p>
            <p className="label-count-emoji">🏄‍♂️</p>
        </div>
    } else if (numLabeled >= 10) {
        return <div className="label-count-line">
            <p className="label-count-emoji">🦾</p>
            <p>Die KI dankt dir!</p>
            <p className="label-count-emoji">🤖</p>
        </div>
    } else {
        return <></>
    }

}