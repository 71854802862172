import React from 'react'

import "./ZoomImage.scss"
import { fetchImageUrl } from "../../utils/ImgUtils.js"
import LoadingIcon from '../LoadingIcon/LoadingIcon.jsx';

export default function ZoomImage({ image_url }) {
    const [imageData, setImageData] = React.useState(null);
    const [zoomIn, setZoomIn] = React.useState(false);
    const imgRef = React.useRef(null);

    React.useEffect(() => {
        /* Load the image on url change */
        setImageData(null);
        if (!image_url) {
            return;
        }

        fetchImageUrl(image_url).then((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setImageData(img);
            }
        })
    }, [image_url])

    const handleMouseMove = (e) => {
        /* See how the mouse moved in order to pan zoomed image */
        const { left, top, width, height } = imgRef.current.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - window.scrollY - top) / height) * 100;
        imgRef.current.style.transformOrigin = `${x}% ${y}%`;
    };
    
    return (
        <div className="zoom-img-container" onMouseMove={handleMouseMove}>
            <img 
                ref={imgRef}
                className={`zoom-img ${zoomIn ? 'zoomed' : ''}`}  
                src={imageData?.src ?? ""} 
                style={{ display: imageData ? 'block' : 'none' }}
                onClick={() => setZoomIn((prev) => !prev)}
            />
            {/* Show loading icon when the image data has not loaded yet */}
            {!imageData && <div className="loading-icon-container"><LoadingIcon /></div>}
        </div>
    )
}